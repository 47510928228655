import React, {useState} from 'react';
import Cian from '../images/cian-new.jpg';
import ReactLogo from '../logo.svg';
import AWSLogo from '../images/AWS-Developer-Associate-2020.png';
import MongoLogo from '../images/MongoDB-Logo.png';
import NodeLogo from '../images/Nodejs.png';
import WordpressLogo from '../images/wordpress.png';
import PhpLogo from '../images/php.png';

const Homepage = () => {
    const [copied, setCopied] = useState(false);
    return (
        <>
            <div className="homepage-banner">
                <div className="overlay">

                    <div className="bio">
                    <img src={AWSLogo} style={{height:150, marginBottom: '1em'}} />
                    <h2>AWS Certified Developer with 9 years' experience.</h2>
                    <p><a href="#!" onClick={(e) => {e.preventDefault(); navigator.clipboard.writeText('cian@cianmulville.com'); setCopied(true); setTimeout(() => {setCopied(false)}, 3000)}}>cian@cianmulville.com&nbsp;&nbsp;<i className="fa-copy fa"></i></a><span>{copied? <>Copied <i className='fa fa-check'></i></> : <></>}</span></p>
                    {/* <p>I'm a strong believer that for most small business websites, Wordpress may be the right choice; while if you want to build something that's never been done before, you need to leverage the best technologies out there. The right solution should:</p>
                    <ul>
                        <li>minimise costs on servers, development &amp; maintenance costs</li>
                        <li>optimise the user experience, with fast performance, ease of use &amp; beautiful design</li>
                    </ul> */}
                </div>
                </div>
                
            </div>
            
        </>
        
    )
}

export default Homepage;