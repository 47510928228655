import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
    <header className="App-header">
        <div className="">
            <div className="flex-row d-flex justify-content-between">
                <h1 className="brand">Cian Mulville</h1>
                {/* <Link onClick={() => window.alert('menu opened')}><i className="fa fa-bars"></i></Link> */}
            </div>
            
            
        </div>
        
    </header>
    )
}

export default Navbar;